import { overflowVisible, overflowHidden, getScrollbarWidth } from '../../utils/overflow';

const Nav = () => {
    const nav = document.getElementById('nav'),
    overlay = document.getElementById('overlay'),
    open = document.getElementById('hamburger'),
    close = document.getElementById('close');
    const header = document.querySelector('.page__row--header');

    const scrollWidth = getScrollbarWidth();

    try {
        const navItemsDropdown = document.querySelectorAll('.nav__item--dropdown');
        const headerOverlay = document.getElementById('headerOverlay');

        navItemsDropdown.forEach((item) => {
            item.addEventListener('mouseenter', () => {
                headerOverlay.style.opacity = 1;
                headerOverlay.style.visibility = 'visible';
            });
          
            item.addEventListener('mouseleave', () => {
                headerOverlay.style.opacity = 0;
                headerOverlay.style.visibility = 'hidden';
            });
        });

        const dropdowns = document.querySelectorAll('.dropdown');

        dropdowns.forEach((dropdown) => {
            const dropdownBlocksGroups = dropdown.querySelectorAll('.dropdown__blocks');
            dropdown.style.setProperty("--nav-item-anim-total", dropdownBlocksGroups.length);
            let total = 0;
            
            dropdownBlocksGroups && dropdownBlocksGroups.length && dropdownBlocksGroups.forEach((item, index) => {
                const itemsAnim = item.querySelectorAll("[data-nav-item-anim]");
                

                if (itemsAnim.length > total) {
                    total = itemsAnim.length;
                    dropdown.style.setProperty("--nav-item-anim-total", itemsAnim.length);
                }
        
                item.style.setProperty("--nav-item-anim-group-number", index);
        
                itemsAnim && itemsAnim.length && itemsAnim.forEach((itemAnim, index) => {
                    itemAnim.style.setProperty("--nav-item-anim-number", index + 1);
                });
            });
        });
    } catch(e) {

    }


    const navOpen = () => {
        nav && (
            nav.classList.remove('open-'),
            nav.classList.add('open')
        );
        overlay.classList.remove('d-none');
        setTimeout(() => {
            overlay && (
                overlay.classList.add('open')
            );
        }, 100);
        header && ( header.style.marginRight = `${scrollWidth}px` );
        overflowHidden();
    }

    const navClose = () => {
        nav && (
            nav.classList.remove('open')
        );
        setTimeout(() => {
            overlay.classList.remove('d-block');
            overlay.classList.add('d-none');
        }, 600);
        overlay && (
            overlay.classList.remove('open')
        );
        setTimeout(() => {
            header && ( header.style.marginRight = 0 );
            overflowVisible();
        }, 600);
    }

    open && open.addEventListener('click', navOpen);
    close && close.addEventListener('click', navClose);
    overlay && overlay.addEventListener('click', navClose);


    (() => {
        function FixHeader() {
            window.addEventListener('scroll', () => {
                const scroll = window.pageYOffset | document.body.scrollTop;

                if (scroll > 0) { header.classList.add('fixed') }
                else if (scroll <= 0) { header.classList.remove('fixed') }
            });
        }

        
        scroll = window.pageYOffset | document.body.scrollTop;

        if (scroll > 150) { header.classList.add('fixed') }
        else if (scroll <= 150) { header.classList.remove('fixed') }

        FixHeader();
    })();
}

export default Nav;