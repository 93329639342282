import Swiper, { Navigation, Pagination, Thumbs, EffectFade, Controller, Autoplay } from "swiper";
import $, { event } from 'jquery';
import Tabs from "../../../plugins/Tabs";
import { WOW } from 'wowjs';

const HomePage = () => {

  let wow = new WOW(
    {
      boxClass:     'wow',      // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset:       100,          // distance to the element when triggering the animation (default is 0)
      mobile:       true,       // trigger animations on mobile devices (default is true)
      live:         true,       // act on asynchronously loaded content (default is true)
      callback:     function(box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null // optional scroll container selector, otherwise use window
    }
  );
  wow.init();

    function throttle(callee, timeout) {
      let timer = null

      return function perform(...args) {
        if (timer) return
        timer = setTimeout(() => {
          callee(...args)
          
          clearTimeout(timer)
          timer = null
        }, timeout)
      }
    }

    // advantages
    try {
      let elems = document.querySelectorAll('.advantages__circle-icon');
      window.addEventListener('scroll', function() {
        elems.forEach((elem) => {
          var value = window.scrollY * 0.45;

          if (window.scrollY > 200 && window.scrollY < 3200) {
            elem.style.animationDuration = '2s';
          }
          if (window.scrollY > 200) {
            elem.style.animationDuration = '1.6s';
          }
          if (window.scrollY > 260) {
            elem.style.animationDuration = '1s';
          }
          if (window.scrollY > 400) {
            elem.style.animationDuration = '.7s';
          }
          if (window.scrollY > 500) {
            elem.style.animationDuration = '.3s';
          }
          if (window.scrollY < 200) {
            elem.style.animationDuration = '0s';
          }
        });
      });
    } catch(e) {

    }

    // scrolling for top

  
    try {
      const scrollToTopBtn = document.querySelector(".scrollToTop");
        const rootElement = document.documentElement;
        const bodyElement = document.body;
        const progressBar = document.getElementById("progress-bar");
        const pathLength = document
          .querySelector("#progress-bar > svg > path")
          .getTotalLength();
      
        scrollToTopBtn.addEventListener("click", () => {
          rootElement.scrollTo({ top: 0, behavior: "smooth" });
        });
      
        document.addEventListener("scroll", () => {
          const scrollAmount = pathLength / 100;
          const scrollPosition = Math.round(
            ((rootElement.scrollTop || bodyElement.scrollTop) /
              ((rootElement.scrollHeight || bodyElement.scrollHeight) -
                innerHeight)) *
              100 *
              scrollAmount
          );
      
          if (scrollPosition > 5) {
            scrollToTopBtn.classList.add("showBtn");
            progressBar.style.setProperty("--scrollAmount", scrollPosition + "px");
          } else {
            scrollToTopBtn.classList.remove("showBtn");
          }
        });
            
    } catch(e) {
      console.log('e', e);
      
    }


    Swiper.use([Pagination, Thumbs, EffectFade, Navigation, Controller, Autoplay]);

    var servicesCarousel = new Swiper(".servicesCarousel", {
      spaceBetween: 30,
      centeredSlides: false,
      allowTouchMove: true,
      loop: false,
      simulateTouch: true,
      navigation: {
        nextEl: '.circle-btn--next',
        prevEl: '.circle-btn--prev',
      },
      watchSlidesProgress: true,
      slideToClickedSlide: false,
      on: {

      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
  });


    var advantagesCarousel = new Swiper('#advantagesCarousel', {
      spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#gameFeaturesCarouselNext',
          prevEl: '#gameFeaturesCarouselPrev',
        },
        pagination: {
          el: '#gameFeaturesCarouselPrev',
          clickable: true
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 4,
          },
        },
    });

    var casesCarousel = new Swiper('#casesCarousel', {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: false,
        allowTouchMove: false,
        loop: false,
        simulateTouch: false,
        navigation: {
          nextEl: '#casesCarouselNext',
          prevEl: '#casesCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 1,
          },
        },
    });

    const setZero = (num) => {
      return num && num < 9 ? `0${num}` : num;
    }

    function updSwiperNumericPagination() {
      
      this.el.querySelector(".counter-nav-pagination__count").innerHTML = '<span class="count">' + (setZero(this.realIndex + 1)) + '</span>/<span class="total">' + setZero(this.el.swiper.slides.length) + "</span>";
    }

    var casesInnerCarousel = new Swiper('.casesInnerCarousel', {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '.counter-nav-pagination__btn--next',
          prevEl: '.counter-nav-pagination__btn--prev',
        },
        pagination: {
          el: "",
          clickable: true,
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          init: updSwiperNumericPagination,
          slideChange: updSwiperNumericPagination
        },
        breakpoints: {
          
        },
    });


    var portfolioCarousel = new Swiper('#portfolioCarousel', {
        spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: true,
        simulateTouch: true,
        speed: 1000,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '#portfolioCarouselNext',
          prevEl: '#portfolioCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
    });

    document.getElementById('portfolioCarousel').addEventListener('mouseenter', () => {
      portfolioCarousel.autoplay.stop();
    });

    document.getElementById('portfolioCarousel').addEventListener('mouseleave', () => {
      portfolioCarousel.autoplay.start();
    });


    var teamCarousel = new Swiper('#teamCarousel', {
        spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#teamCarouselNext',
          prevEl: '#teamCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          },
        },
    });

    var clientsCarousel = new Swiper('#clientsCarousel', {
        spaceBetween: 7,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: false,
        navigation: {
          nextEl: '#gameFeaturesCarouselNext',
          prevEl: '#gameFeaturesCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
            spaceBetween: 42,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 7,
          },
        },
    });

    

    var reviewsCarousel = new Swiper('#reviewsCarousel', {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '.reviewsCarouselNext',
          prevEl: '.reviewsCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        thumbs: {
          swiper: clientsCarousel
        },
        breakpoints: {
          
        },
    });

    

    clientsCarousel.params.control = reviewsCarousel;
    reviewsCarousel.params.control = clientsCarousel;


    var articlesCarousel = new Swiper('#articlesCarousel', {
        spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#articlesCarouselNext',
          prevEl: '#articlesCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          },
        },
    });

    const playVideo = (event) => {

      event.slides.forEach((item) => {
        const video = item.querySelector('video');
        video && video.pause();
      });
      
      const activeElement = event.slides[event.activeIndex];
      const video = activeElement && activeElement.querySelector('video');
      
      video && video.play();
    }


  const servicesTabs = new Tabs({
    tabSelectorById: '#servicesTabs',
  });
  
}

export default HomePage;