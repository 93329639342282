import toggleHTMLClass from "../utils/toggleHTMLClass";

function Tabs(options) {
    const {
        tabSelectorById
    } = options || {};
    
    

    this.tabSelectorById = tabSelectorById && tabSelectorById.slice(1);
    
    this.tabElement = document.getElementById(`${this.tabSelectorById}`);
    this.tabButtonItems = this.tabElement && this.tabElement.querySelectorAll(`[data-js-tab-button="${tabSelectorById}"]`);
    this.tabContentItems = this.tabElement && this.tabElement.querySelectorAll(`[data-js-tab-content-item="${tabSelectorById}"]`);


    this.hideTabContent = () => {
        this.tabContentItems.forEach((item) => {
            item.classList.add('d-none');
            item.setAttribute('data-fade-in', '');
            item.classList.remove('d-block');
        });

        this.tabButtonItems.forEach((tabButtonItem) => {
            toggleHTMLClass({
                element: tabButtonItem,
                type: 'remove'
            });

            tabButtonItem.classList.remove('active');
        });
    }

    this.showTabContent = (i) => {
        const tabButtonElement = this.tabButtonItems[i];
        const tabContentItemElement = this.tabContentItems[i];
        
        tabButtonElement && (
            toggleHTMLClass({
                element: tabButtonElement,
                type: 'add'
            })
        );

        tabButtonElement.classList.add('active');
        
        tabContentItemElement && tabContentItemElement.classList.add('d-block');
        tabContentItemElement && tabContentItemElement.classList.remove('d-none');
    }
    

    if (this.tabElement) {
        this.hideTabContent();
        this.showTabContent(0);

        this.tabButtonItems.forEach((tabButtonItem, index) => {
            tabButtonItem.addEventListener('click', () => {
                this.hideTabContent();
                this.showTabContent(index);
            });
        });
    }
}

export default Tabs;