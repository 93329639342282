import HomePage from "../containers/Main/HomePage";
import Nav from "../components/Nav";
import toggle from "../utils/toggle";
import Modal from "../plugins/Modal";


const routesConfig = [
    {
        pages: ['/', '/index'],
        callbacks: [
            HomePage,
        ],
    },
    {
        pages: ['*'],
        callbacks: [
            Nav,
            toggle,
            Modal
        ]
    }
];

export default routesConfig;